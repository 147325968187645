<style lang="scss" scoped>
.ocr-nano-container {
  .ocr-nano-img-container {
    position: relative;
    overflow: hidden;
  }
  .overlay-options {
    padding: 1em;
    display: flex;
    .custom-control-label {
      padding-top: 0.25rem;
    }
  }
  .ocr-nano-img-title {
    font-size: 200%;
    margin: 1em 0px;
  }
  .ocr-nano-separator {
    border-top: lightgray 2px dotted;
    width: 100%;
    height: 2px;
    margin-top: 2em;
  }
  .ocr-nano-overlay {
    position: absolute;
    border: 2px dashed #FF3670;
    color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #f7699181;
      color: white;
    }
    .index {
      font-size: 80%;
    }
  }
}
.ocr-nano-collapse-container {
  margin: 1em 0px;
  font-size: 120%;
  .button-collapse {
    font-size: 130%;
    text-transform: capitalize;
    &:focus {
      outline: none;
    }
    &.collapsed {
      .when-open {
        display: none;
      }
    }
    &.not-collapsed {
      .when-closed {
        display: none;
      }
    }
  }
  .ocr-nano-content {
    padding: 1em;
  }
}
.ocr-gs-container {
  .ocr-gs-img-container {
    position: relative;
    overflow: hidden;
  }
  .overlay-options {
    padding: 1em;
    display: flex;
    .custom-control-label {
      padding-top: 0.25rem;
    }
  }
  .ocr-gs-img-title {
    font-size: 200%;
    margin: 1em 0px;
  }
  .ocr-gs-separator {
    border-top: lightgray 2px dotted;
    width: 100%;
    height: 2px;
    margin-top: 2em;
  }
  .ocr-gs-overlay {
    position: absolute;
    border: 2px dashed #FF3670;
    color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #f7699181;
      color: white;
    }
    .index {
      font-size: 80%;
    }
  }
}
.ocr-gs-collapse-container {
  margin: 1em 0px;
  font-size: 120%;
  .button-collapse {
    font-size: 130%;
    text-transform: capitalize;
    &:focus {
      outline: none;
    }
    &.collapsed {
      .when-open {
        display: none;
      }
    }
    &.not-collapsed {
      .when-closed {
        display: none;
      }
    }
  }
  .ocr-gs-content {
    padding: 1em;
  }
}
.tooltip {
  &.ocr-nano-tooltip {
    font-size: 120%;
    max-width: 100vw;
    .tooltip-inner {
      text-align: left;
      max-width: unset;
    }
  }
  &.ocr-gs-tooltip {
    font-size: 120%;
    max-width: 100vw;
    .tooltip-inner {
      text-align: left;
      max-width: unset;
    }
  }
}
.ocr-nano-container {
  .overlay-options {
    .custom-control-label {
      padding-top: 0.25rem;
    }
  }
}
.ocr-gs-container {
  .overlay-options {
    .custom-control-label {
      padding-top: 0.25rem;
    }
  }
}
table {
  &.ocr-nano-table {
    border: 1px solid black;
    th, td {
      border: 1px solid black;
    }
  }
  &.ocr-gs-table {
    border: 1px solid black;
    th, td {
      border: 1px solid black;
    }
  }
}
</style>
<template>
  <div class="d-flex flex-row">

    <!--begin::Content-->
    <div class="flex-row-fluid">
      <!--begin::Card-->
      <div class="card card-custom position-relative">
        <LoaderPartial v-if="showLoader"></LoaderPartial>
        <!--begin::Header-->
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">
              Resultados
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1"
              >Valida la información obtenida por el servicio</span
            >
          </div>
          <div class="card-toolbar" v-if="!validation.isValidated">
            <button
              type="button"
              class="btn btn-success mr-2"
              @click="save(false)"
              ref="kt_save_changes"
            >
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-primary mr-2"
              @click="save(true)"
              ref="kt_review_changes"
              :disabled="!documentValidation"
            >
              Validar
            </button>
          </div>
          <div class="card-toolbar" v-if="validation.isValidated && validation.validatedBy"> Validado por: {{validation.validatedBy}}</div>
        </div> 
        <!--end::Header-->
        <!--begin::Form-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10" >
          <div class="col-xl-5 col-xxl-5" >
            
            <div class="ocr-nano-container">
              <div class="ocr-nano-results-container">
                <div class="ocr-nano-img-container">
                  <object 
                      :data="validation.documentUrl" 
                      type="application/pdf" 
                      style="width:100%;min-height:590px;"
                      v-if="validation.documentUrl.includes('.pdf')"
                  >
                  </object>
                  <img
                    v-else
                    :src="validation.documentUrl"
                    style="width:100%"
                    :ref="'ocrOrigImage_'+validation.documentName"
                  />
                  <!-- <div class="ocr-nano-overlay-container" v-if="ocrImgDims['ocrOrigImage_'+indexResult]">
                    <div
                      v-for="(prediction, indexPrediction) in result.prediction"
                      :key="indexPrediction"
                      class="ocr-nano-overlay"
                      :style="getOcrOverlayStyle(prediction, 'ocrOrigImage_'+indexResult)"
                      v-b-tooltip.hover.left="{
                        customClass: 'ocr-nano-tooltip',
                        html: true
                      }"
                      :title="getOcrTooltipContent(prediction)"
                      v-show="showOverlay"
                    >
                      <b-badge pill variant="primary" class="index" v-show="showIndexes">#{{indexPrediction+1}}</b-badge>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-7 col-xxl-7">
            <form class="form">
              <!--begin::Body-->
              <div class="tab-content position-relative">
                <!--begin::Table-->
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                  >
                    <thead>
                      <tr>
                        <th>
                          Código
                        </th>
                        <th>
                          Info
                        </th>
                        <th>
                          Revisar
                        </th>
                        <th>
                          Validar
                        </th>
                        <!-- <th>
                          Editar
                        </th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, i) in validation.response[0]">
                        <tr v-bind:key="i">
                          <td class="pl-2 py-8">
                            <div>
                              <b>{{i+1}}</b>. {{item.label}}
                            </div>
                          </td>
                          <td>
                            <input 
                              ref="value"
                              type="text"
                              class="form-control form-control-lg form-control-solid"
                              v-model="item.ocr_text"
                              :disabled="validation.isValidated"
                            />
                          </td>
                          <td class="text-center">
                            <label>
                              <i :class="`far fa-eye ${item.toReview ? 'text-primary':''}`"></i>
                              <input type="checkbox" style="display:none" v-model="item.toReview" :disabled="validation.isValidated"/>
                            </label>
                          </td>
                          
                          <td class="text-center hover">
                            <label>
                              <i class="fas fa-check-circle text-primary" v-if="item.isValidated"></i>
                              <i class="far fa-circle text-primary" v-else></i>
                              <input type="checkbox" style="display:none" v-model="item.isValidated" :disabled="validation.isValidated"/>
                            </label> 
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--end::Body-->
            </form>
          </div>
        </div>
        <!--end::Form-->
      </div>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import LoaderPartial from "@/view/content/LoaderPartial.vue";
import { cloneDeep } from "lodash";

export default {
  name: "validation",
  components: {
    LoaderPartial
  },
  data() {
    return {
      resource: "request-validations",
      ocrImgDims: {},
      ocrDimsReady: {},
      showIndexes: false,
      showOverlay: true,
      validation: {
        documentUrl: "",
        documentName: "",
        originalResponse: {},
        response: [[],{}],
        validatedBy: {
          id: "",
          name: "",
        }
      },
      showLoader: true,
    };
  },
  computed: {
    documentValidation() {
      return this.validation.response[0].every(each => each.isValidated)
    }
  },
  // validations () {
  //   return {
  //     documentValidation: {
  //       $each: {
  //         isValidated: {
  //           required,
  //           checked: value => value == true
  //         }
  //       }
  //     },
  //     editValidation: ["documentValidation.fields"]

  //   }
    
  //},
  async mounted() {

    if (this.$route.params.id) {
      console.log("Validation existent");
      let getResource;
      try {
        getResource = await this.getResource(this.$route.params.id);
      } catch (e) {
        console.log(404);
        this.$router.push({ name: "404" });
      }
      console.log(getResource)
      this.validation = getResource;
      //this.current_photo = this.validation.image;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Validaciones", route: "/validations"}, {title: "Validación de documento"}]);
    }else{
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Validaciones", route: "/validations"}, {title: "Validación de documento"}]);
    }
    this.showLoader = false;
  },
  methods: {
    async getResource(id) {
      let { data } = await ApiService.get(`${this.resource}`, id);
      //console.log(data);
      return data;
    },
    async save(close) {
      // set spinner to submit button
      let submitButton = this.$refs["kt_save_changes"];
      if(close) submitButton = this.$refs["kt_review_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      // dummy delay
      setTimeout(async () => {
        // send update request
        try {
          this.validation.isValidated = close
          this.validation.documentUrl = this.validation.documentUrl.replaceAll(" ","%20");
          console.log(this.validation.documentUrl)
          let { id, ...currentValidation } = Object.entries(this.validation).reduce(
            (a, [k, v]) => (v ? ((a[k] = v), a) : a),
            {}
          );
          
          await ApiService.patch(`${this.resource}/${id}`, currentValidation);
          Swal.fire({
            title: "",
            text: "La validación ha sido guardada exitosamente!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          }).then(function() {
            window.location.href = "#/validations";
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        } catch (e) {
          console.log(e);
          Swal.fire({
            title: "El validación no ha sido guardada",
            text: "Por favor revisa que los datos ingresados sean correctos",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        }
      }, 500);
    },
    getOcrOrigImage(response, filepath) {
      // let style = "background-image:url('" +
      //   this.testResponse.signed_urls[Object.keys(this.testResponse.signed_urls)[0]].original +
      //   "')";
      // return style;
      let url = "";
      if (response.signed_urls) {
        url = response.signed_urls[filepath].original;
      }
      // console.log(url);
      return url;
    },
    getOcrOverlayStyle(prediction, ref, padding = 3) {
      // console.log("this.ocrImgDims[ref]", this.ocrImgDims[ref])
      let style = "";
      if ( prediction.label == "raw_text" ||
        (
          prediction.xmin == 0 &&
          prediction.xmax == 0 &&
          prediction.ymax == 0 &&
          prediction.ymin == 0 
        )
        ) {
          style = "display: none";
        }
      else if (this.ocrImgDims[ref]) {
        let xProp = this.ocrImgDims[ref].dom.width / this.ocrImgDims[ref].original.width;
        let yProp = this.ocrImgDims[ref].dom.height / this.ocrImgDims[ref].original.height;
        let left = Math.round(xProp * prediction.xmin);
        let width = Math.round(xProp * (prediction.xmax - prediction.xmin));
        let top = Math.round(yProp * prediction.ymin);
        let height = Math.round(yProp * (prediction.ymax - prediction.ymin));
        style = "left:" + (left - padding) + "px;";
        style += "width:" + (width + padding * 2) + "px;";
        style += "top:" + (top - padding) + "px;";
        style += "height:" + (height + padding * 2) + "px;";
      }
      if ( prediction.type && prediction.type == "table") {
        style += "z-index: 0;";
      }
      else {
        style += "z-index: 1;";
      }
      // console.log("style", style);
      return style;
    },
    OcrImageGetDims(ref) {
      // console.log(ref);
      if (this.$refs[ref]) {
        // this.ocrImgDims[ref] = {
        //   original:{},
        //   dom:{},
        //   overlayStyles:[]
        // };
        let dims = {
          original:{
            height: this.$refs[ref][0].naturalHeight,
            width: this.$refs[ref][0].naturalWidth
          },
          dom:{
            height: this.$refs[ref][0].clientHeight,
            width: this.$refs[ref][0].clientWidth
          }
        }
        // console.log(dims);
        this.$set(this.ocrImgDims, ref, cloneDeep(dims));
      } 
      
    },
    getOcrTooltipContent(prediction, contentOnly = false) {
      let string = "";
      if (contentOnly) {
        if(prediction.type && prediction.type == "table") {
          string = this.parseTableResponse(prediction.cells);
        }
        else {
          string = prediction.ocr_text.replace(/\n/ig, '<br>');
        }
        return string;
      }
      else {
        if(prediction.type && prediction.type == "table") {
          string += "<strong>" +
            "Categoría" +
            ":</strong> " +
            this.$t("SERVICE.OCR_OPTIONS.TABLE") +
            "<br>";
          string += "<strong>" +
            "Certeza" +
            ":</strong> " +
            Math.round(prediction.score) +
            "%" +
            "<br>";
          string += "<strong>" +
            "Contenido" + ":</strong><br>" + this.parseTableResponse(prediction.cells);
        }
        else {
          string += "<strong>" +
            "Categoría" +
            ":</strong> " +
            prediction.label +
            "<br>";
          string += "<strong>" +
            "Certeza" +
            ":</strong> " +
            Math.round(prediction.score * 100) +
            "%" +
            "<br>";
          string += "<strong>" +
            "Content" + ":</strong><br>" + prediction.ocr_text.replace(/\n/ig, '<br>');
        }
        return string;
      }
    },
  }
  
};
</script>
