var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-row" }, [
    _c("div", { staticClass: "flex-row-fluid" }, [
      _c(
        "div",
        { staticClass: "card card-custom position-relative" },
        [
          _vm.showLoader ? _c("LoaderPartial") : _vm._e(),
          _c("div", { staticClass: "card-header py-3" }, [
            _vm._m(0),
            !_vm.validation.isValidated
              ? _c("div", { staticClass: "card-toolbar" }, [
                  _c(
                    "button",
                    {
                      ref: "kt_save_changes",
                      staticClass: "btn btn-success mr-2",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.save(false)
                        }
                      }
                    },
                    [_vm._v(" Guardar ")]
                  ),
                  _c(
                    "button",
                    {
                      ref: "kt_review_changes",
                      staticClass: "btn btn-primary mr-2",
                      attrs: {
                        type: "button",
                        disabled: !_vm.documentValidation
                      },
                      on: {
                        click: function($event) {
                          return _vm.save(true)
                        }
                      }
                    },
                    [_vm._v(" Validar ")]
                  )
                ])
              : _vm._e(),
            _vm.validation.isValidated && _vm.validation.validatedBy
              ? _c("div", { staticClass: "card-toolbar" }, [
                  _vm._v(" Validado por: " + _vm._s(_vm.validation.validatedBy))
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass:
                "row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
            },
            [
              _c("div", { staticClass: "col-xl-5 col-xxl-5" }, [
                _c("div", { staticClass: "ocr-nano-container" }, [
                  _c("div", { staticClass: "ocr-nano-results-container" }, [
                    _c("div", { staticClass: "ocr-nano-img-container" }, [
                      _vm.validation.documentUrl.includes(".pdf")
                        ? _c("object", {
                            staticStyle: {
                              width: "100%",
                              "min-height": "590px"
                            },
                            attrs: {
                              data: _vm.validation.documentUrl,
                              type: "application/pdf"
                            }
                          })
                        : _c("img", {
                            ref: "ocrOrigImage_" + _vm.validation.documentName,
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.validation.documentUrl }
                          })
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-xl-7 col-xxl-7" }, [
                _c("form", { staticClass: "form" }, [
                  _c("div", { staticClass: "tab-content position-relative" }, [
                    _c("div", { staticClass: "table-responsive" }, [
                      _c(
                        "table",
                        {
                          staticClass:
                            "table table-head-custom table-vertical-center table-head-bg table-borderless"
                        },
                        [
                          _vm._m(1),
                          _c(
                            "tbody",
                            [
                              _vm._l(_vm.validation.response[0], function(
                                item,
                                i
                              ) {
                                return [
                                  _c("tr", { key: i }, [
                                    _c("td", { staticClass: "pl-2 py-8" }, [
                                      _c("div", [
                                        _c("b", [_vm._v(_vm._s(i + 1))]),
                                        _vm._v(". " + _vm._s(item.label) + " ")
                                      ])
                                    ]),
                                    _c("td", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.ocr_text,
                                            expression: "item.ocr_text"
                                          }
                                        ],
                                        ref: "value",
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-lg form-control-solid",
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.validation.isValidated
                                        },
                                        domProps: { value: item.ocr_text },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              item,
                                              "ocr_text",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      _c("label", [
                                        _c("i", {
                                          class:
                                            "far fa-eye " +
                                            (item.toReview
                                              ? "text-primary"
                                              : "")
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.toReview,
                                              expression: "item.toReview"
                                            }
                                          ],
                                          staticStyle: { display: "none" },
                                          attrs: {
                                            type: "checkbox",
                                            disabled: _vm.validation.isValidated
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              item.toReview
                                            )
                                              ? _vm._i(item.toReview, null) > -1
                                              : item.toReview
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = item.toReview,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      item,
                                                      "toReview",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      item,
                                                      "toReview",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(item, "toReview", $$c)
                                              }
                                            }
                                          }
                                        })
                                      ])
                                    ]),
                                    _c(
                                      "td",
                                      { staticClass: "text-center hover" },
                                      [
                                        _c("label", [
                                          item.isValidated
                                            ? _c("i", {
                                                staticClass:
                                                  "fas fa-check-circle text-primary"
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "far fa-circle text-primary"
                                              }),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.isValidated,
                                                expression: "item.isValidated"
                                              }
                                            ],
                                            staticStyle: { display: "none" },
                                            attrs: {
                                              type: "checkbox",
                                              disabled:
                                                _vm.validation.isValidated
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                item.isValidated
                                              )
                                                ? _vm._i(
                                                    item.isValidated,
                                                    null
                                                  ) > -1
                                                : item.isValidated
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a = item.isValidated,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        item,
                                                        "isValidated",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        item,
                                                        "isValidated",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    item,
                                                    "isValidated",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    )
                                  ])
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-title align-items-start flex-column" },
      [
        _c("h3", { staticClass: "card-label font-weight-bolder text-dark" }, [
          _vm._v(" Resultados ")
        ]),
        _c(
          "span",
          { staticClass: "text-muted font-weight-bold font-size-sm mt-1" },
          [_vm._v("Valida la información obtenida por el servicio")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(" Código ")]),
        _c("th", [_vm._v(" Info ")]),
        _c("th", [_vm._v(" Revisar ")]),
        _c("th", [_vm._v(" Validar ")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }